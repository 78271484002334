import React, { useRef, useState } from 'react';
import ReactFullpage, { fullpageApi } from '@fullpage/react-fullpage';
import { useMediaQuery } from 'usehooks-ts';
import AboutMe from './components/about';
import Thinking from './components/Thinking';
import ProjectShowcase from './components/showcase';
import JigSawPuzzle from './components/jigsaw-puzzle';
import { projects } from './components/Projects/projects';
import Contact from './components/Contact/Contact';
import "./overrides.css";

function MainContent() {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const anchors = isMobile
    ? ["WelcomePage", "Ready", "Job Hunt UK", "Select AI", "Modern Dropzone","Contact", "Jigsaw"]
    : ["WelcomePage", "Ready", "Projects", "Contact","Jigsaw"];

    const fullpageApiRef = useRef<fullpageApi | null>(null);

  // Plugin wrapper for loading the scrollHorizontally extension
  const pluginWrapper = () => {
    require("../src/assets/js/fullpage.scrollHorizontally.min.js");
  };

  return (
    <ReactFullpage
      pluginWrapper={pluginWrapper}
      licenseKey={process.env.REACT_APP_FULLPAGE_API_KEY??""}
      scrollHorizontallyKey={process.env.REACT_APP_FULLPAGE_HORIZONTAL_API_KEY??""}
      navigation={true}
      scrollingSpeed={1100}
      controlArrows={false}
      scrollHorizontally={true} // Enables horizontal scrolling in target sections
      anchors={anchors}
      verticalCentered={false}
      credits={{ enabled: false }}
      
      render={({ fullpageApi }) => {
        fullpageApiRef.current = fullpageApi;
        return (
          <ReactFullpage.Wrapper>
            {/* Section 1 - Vertical Scroll */}
            <div className="section h-screen w-screen flex flex-col justify-center overflow-hidden p-8">
              <div className="flex flex-col items-center justify-center">
                <AboutMe />
              </div>
            </div>

            {/* Section 2 - Vertical Scroll */}
            <div className="section h-screen w-screen flex items-center justify-center overflow-hidden">
              <div className="flex flex-col items-center justify-around md:justify-center">
                <div className="bg-mc-200 p-8 md:rounded-md shadow-lg">
                  <h1 className="text-4xl text-center">
                  <span className="block text-mc-950">Ready to see what I’ve built?</span>
                </h1>
                </div>
                <Thinking />
              </div>
            </div>

            {/* Projects Section with Horizontal Slides */}
            <div className="section">
              <div className="slide h-screen w-screen flex items-center justify-center overflow-hidden">
                <ProjectShowcase project={projects[0]} />
              </div>
              <div className="slide h-screen w-screen flex items-center justify-center overflow-hidden">
                <ProjectShowcase project={projects[1]} />
              </div>
              <div className="slide h-screen w-screen flex items-center justify-center overflow-hidden">
                <ProjectShowcase project={projects[2]} />
              </div>
            </div>

             
            <div className="section contact-form h-screen w-screen flex items-center justify-center overflow-hidden">
            <div className="flex flex-col items-center justify-around md:justify-center">
                <Contact/>
              </div>
            </div>

            {/* Jigsaw Puzzle Section - Vertical Scroll */}
            <div className="section jigsaw-div h-screen w-screen flex flex-col justify-center overflow-hidden p-4">
              <div className="flex flex-col items-center justify-center flex-grow space-y-6">
                <h1 className="text-4xl font-bold text-gray-800 mb-6">Jigsaw Puzzle</h1>
                <JigSawPuzzle />
              </div>
            </div>
          </ReactFullpage.Wrapper>
        );
      }}
    />
  );
}

export default MainContent;
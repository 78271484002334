import { Project } from "../../interfaces/interface";
import ModernDataGrid from "../../assets/ModernDataGrid.png";
import SelectAI from "../../assets/SelectAI.png";
import ModernDropzone from "../../assets/ModernDropzone.png";
export const projects: Project[] = [
  {
    title: "Modern Dropzone",
    description:
      "A control to upload files using a modern interface. It integrates perfectly with tables that have notes enabled.",
    imageUrl: ModernDropzone,
    link: "https://pcf.gallery/modern-dropzone-pcf/",
  },
  {
    title: "Modern Data Grid",
    description:
      "Modern Data Grid component for Canvas apps",
    imageUrl: ModernDataGrid,
    link: "https://pcf.gallery/modern-data-grid/",
  },
  {
    title: "Select AI",
    description:
      "Enhance browsing with Select AI. Select AI, the ultimate Chrome extension designed to enhance your browsing experience.",
    imageUrl:
      SelectAI,
    link: "https://chromewebstore.google.com/detail/select-ai/akencabglgnbjflmlmflddmocjbeghld",
  },
  
  ];